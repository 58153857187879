import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';

export interface Item {
  id: number | string;
  avatar:string;
  last_name: string;
  first_name: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataMenusService {
  menuItems: Observable<any[]>
  private _data: BehaviorSubject<any[]>;
  private dataStore: {
    webMenus: any[]
  };
  constructor(
    private http: HttpClient
    , private authService: AuthService
    , private apiService: ApiService) {
    this.dataStore = { webMenus : []}
    this._data = <BehaviorSubject<any[]>>new BehaviorSubject([]);
    this.menuItems = this._data.asObservable();
    this.loadData();
  }

  get data() {
    return this._data.asObservable();
  }

  loadData() {
    this.apiService.getWebMenuListByUserId(this.authService.getClaims().sub, '6F84B537-05F2-4B11-A891-67B4EF1847DC')
      .subscribe(res => {
        if(res.status === 'success') {
          this.dataStore.webMenus = res.data.webMenus;
          console.log("data:: ",  this.dataStore.webMenus );
          this._data.next(Object.assign({}, this.dataStore).webMenus);
        }
      }, error => console.log('Could not load todos.'));
  };


}
